import { sectionsWithDataProps } from '@webplatform/asbis-ui/dist/sections';
import kebabToPascal from './kebabToPascal';

const componentsAliases = {};

const getComponentType = (type) => componentsAliases[type] ?? type;

const getItemsData = (data) => {
  return Array.isArray(data) ? { items: data } : data;
};

const isComponentsWithDataProps = (name) => {
  return name ? sectionsWithDataProps.includes(kebabToPascal(name)) : false;
};

export default (block) => {
  const isAsbisUiFromCatalog =
    block.content_type && block.content_type === 'asbis-ui' && !block.content;

  if (block.type === 'textarea') {
    return { type: 'prose-block', data: block };
  }

  if (isAsbisUiFromCatalog)
    return {
      type: getComponentType(block.type),
      data: !isComponentsWithDataProps(block.type)
        ? getItemsData(block.data.data)
        : { data: getItemsData(block.data.data) },
    };
  return {
    ...block.content,
    type: getComponentType(block.content.type),
    data: !isComponentsWithDataProps(block.content.type)
      ? getItemsData(block.content.data)
      : { data: getItemsData(block.content.data) },
  };
};
