
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { forEachSections } from '@webplatform/asbis-ui/dist/sections';
import prepareDynamicBlock from '~/helpers/prepareDynamicBlock';

const asbisUiSections = {};

forEachSections((sectionName, sectionComponent) => {
  if (sectionName !== 'HeroSection')
    asbisUiSections[sectionName] = sectionComponent;
});
export default defineComponent({
  components: asbisUiSections,
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const components = computed(() =>
      props.blocks
        ?.map((block) => prepareDynamicBlock(block))
        .filter((block) => block.type !== 'short_description')
    );

    const getComponentToRender = (componentType) =>
      Object.keys(asbisUiSections).includes(componentType)
        ? asbisUiSections[componentType]
        : componentType;

    return {
      components,
      getComponentToRender,
    };
  },
});
